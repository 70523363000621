import { initEventTracking } from '../helpers/initEventTracking';
import { initFooter } from '../helpers/initFooter';
import { initHeader } from '../helpers/initHeader';
import { initModals } from '../helpers/initModals';

export default {
	// JS to be fired on all pages
	init() {
		initSite();
		initEventTracking();
		initHeader();
		initFooter();
		initModals();
	},
	// JS to be fired on all pages, after page-specific JS is fired
	finalize() {
		if (typeof trackEvent === 'function') {
			addTrackingListeners();
		}
	},
};

function initSite() {
	// inject any non-blocking stylesheets:
	$(window).on('load', function () {
		$('head').append($('#nonblocking').text());
	});

	// Prevent disabled button clicks
	$('a.disabled').on('click', function (e) {
		e.preventDefault();
	});

	// If IE 11
	if ($('body').hasClass('kysc-mirror-blocked')) {
		$('.logo-svg').css('position', 'absolute');
	}

	// Remove Versai Template inline styles
	$('.versai-template-content').find('ul, li, p, font').attr({
		style: '',
		size: '',
		face: '',
	});
}

function addTrackingListeners() {
	// Immediately send conversion event
	gtag('event', 'conversion', {
		send_to: 'AW-10797953095/bNLqCKjP0pMDEMfg7pwo',
	});

	// Footer social link tracking
	$('.social-links li a').on('click', function () {
		// Track event
		trackEvent('social', 'click', 'social', $(this).attr('href'));
	});

	// Footer subscribe to newsletter link
	$('.footer-links li a').on('click', function () {
		if ($(this).text().trim().toLowerCase().indexOf('subscribe') != -1) {
			// Track event
			trackEvent(
				'subscribe',
				'click',
				'subscribe_to_newsletter',
				'click_subscribe_to_newsletter_link'
			);
		}
	});

	// Track all buttons on site that do NOT have the following classes
	setTimeout(function () {
		$('.btn')
			.not(
				'.event-tracking-bound, .btn-outline, .program-details-modal, .camp-details-modal, .bio-details-modal'
			)
			.on('click', function () {
				var pageTitle = $('body')
					.find('h1:first')
					.text()
					.trim()
					.replace(/ /g, '_');
				if (pageTitle.length < 1) {
					pageTitle = $(document)
						.attr('title')
						.trim()
						.replace(/ - Kentucky Science Center/g, '')
						.replace(/ /g, '_');
				}
				trackEvent(
					'button_click',
					'button_click',
					pageTitle.toLowerCase(),
					$(this).text().toLowerCase().replace(/ /g, '_')
				);
			});
	}, 200);
}

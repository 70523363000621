export default {
	init() {
		// Bind Movie Showtimes on page load
		if ($('.movie-listing').length) {
			bindMobileMovieShowtimes();
		}
	},
	finalize() {},
};

// Expand / Collapse Movie Showtimes
function bindMobileMovieShowtimes() {
	$('.movie-listing').on('click', '.mobile-showtimes-header', function (e) {
		e.preventDefault();

		// Unbind click to allow animation to complete
		$(this).off('click');

		const viewLink = $(this);
		const showtimes = viewLink.closest('.movie-listing').find('.showtimes');

		if (viewLink.hasClass('open')) {
			// Collapse menu item
			showtimes.slideUp('fast', function () {
				viewLink.removeClass('open');
				$(this).removeClass('open');

				// Rebind
				bindMobileMovieShowtimes();
			});
		} else {
			// Open menu item
			showtimes.slideDown('fast', function () {
				viewLink.addClass('open');
				$(this).addClass('open');

				// Rebind
				bindMobileMovieShowtimes();

				// Track event
				trackEvent(
					'movie_showtimes',
					'movie_showtimes_mobile_view',
					'movie_showtimes',
					'movie_showtimes_click_to_view'
				);
			});
		}
	});
}

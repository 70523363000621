export default {
	init() {},
	finalize() {
		if ($('.kysc-page-sponsor').length && typeof trackEvent === 'function') {
			$.each($('.header-block').find('a'), function () {
				// If sponsor link
				$(this).on('click', function () {
					trackEvent(
						'sponsor_click',
						'sponsor_click',
						'sponsor',
						$(this)
							.closest('.header-block')
							.find('h4')
							.text()
							.toLowerCase()
							.replace(/ /g, '_')
					);
				});
			});

			$.each($('.sponsor-list').find('a'), function () {
				// If sponsor link
				$(this).on('click', function () {
					trackEvent(
						'sponsor_click',
						'sponsor_click',
						'sponsor',
						$(this).text().toLowerCase().replace(/ /g, '_')
					);
				});
			});
		}
	},
};

export function initModals() {
	// init global modal funtions
	window.showModal = handleModalShow;
	window.closeModal = handleModalClose;

	// Cancel Button closes modal
	$('.modal').on('click', '.modal-cancel', function (e) {
		e.preventDefault();
		handleModalClose('#' + $(this).closest('.modal').attr('id'));
	});

	// Trigger modal on .program-details-modal or .camp-details-modal button click
	$('.program-details-modal, .camp-details-modal').on('click', function (e) {
		e.preventDefault();

		const contentElement = $(this).closest('.color-block');
		const theModalContent = contentElement.find('.hidden-modal-content').html();

		handleModalShow('#kysc-modal', theModalContent, contentElement);
	});

	// Trigger modal on .bio-details-modal link click
	$('.bio-details-modal').on('click', function (e) {
		e.preventDefault();

		const contentElement = $(this).closest('.header-block');
		const theModalContent = contentElement.find('.hidden-modal-content').html();

		handleModalShow('#kysc-modal', theModalContent, contentElement);
	});

	// Wrap all tables in a wrapper that scrolls horizontally
	$('.hidden-modal-content table').each(function () {
		var _this = $(this);
		if (_this.parent('.allow-table-scroll').length == 0) {
			$(
				'<p class="table-scroll-message">- Scroll table left or right -</p>'
			).insertBefore(_this);
			_this.wrap('<div class="allow-table-scroll"></div>');
		}
	});
}

function handleModalClose(selector) {
	const modal = $(selector);
	const modalOverlay = $('.modal-overlay');
	const modalTitle = modal.find('.modal-title');
	const modalContent = modal.find('.modal-content');
	const body = $('body');

	let closeButton = null;
	let modalTopCalloutContainer = null;
	let modalTopCallout = null;
	let modalPreheader = null;
	let modalSubheader = null;

	// If not kysc-mirror-modal
	if (selector != '#kysc-mirror-modal') {
		closeButton = modal.find('.modal-close');
		modalTopCalloutContainer = modal.find('.modal-topcallout-container');
		modalTopCallout = modal.find('.modal-topcallout');
		modalPreheader = modal.find('.modal-preheader');
		modalSubheader = modal.find('.modal-subheader');
	}

	// Scroll back to top
	modal.animate(
		{
			scrollTop: 0,
		},
		1,
		function () {
			// Reset modal
			modal.removeClass('modal-open');
			modalOverlay.removeClass('modal-open');
			body.removeClass('modal-open');

			// If not kysc-mirror-modal
			if (selector != '#kysc-mirror-modal' && modalTopCallout != null) {
				// Empty fields
				modalTopCallout.empty();
				modalTopCalloutContainer.hide();
				modalPreheader.empty();
				modalTitle.attr('class', 'modal-title').empty();
				modalSubheader.empty();
			}

			modalContent.empty();
		}
	);
}

/**
 * Handler to show the modal
 *
 * @param {string} selector
 * @param {string} content
 * @param {HTMLElement} contentElement
 *
 * @example showModal('#kysc-modal', [html_to_inject], [contentElement-selector with data-attributes])
 */
function handleModalShow(selector, content, contentElement) {
	const modal = $(selector);
	const modalOverlay = $('.modal-overlay');
	const modalTitle = modal.find('.modal-title');
	const modalContent = modal.find('.modal-content');
	const body = $('body');

	let closeButton = null;
	let modalTopCalloutContainer = null;
	let modalTopCallout = null;
	let modalPreheader = null;
	let modalSubheader = null;

	// contentElement vars
	let topCallout = null;
	let preheaderText = null;
	let titleColorClass = null;
	let titleText = null;
	let subheaderText = null;

	// If not kysc-mirror-modal
	if (selector != '#kysc-mirror-modal') {
		closeButton = modal.find('.modal-close');
		modalTopCalloutContainer = modal.find('.modal-topcallout-container');
		modalTopCallout = modal.find('.modal-topcallout');
		modalPreheader = modal.find('.modal-preheader');
		modalSubheader = modal.find('.modal-subheader');
		// contentElement vars
		topCallout = contentElement.data('topcallout');
		preheaderText = contentElement.data('preheader');
		titleColorClass = contentElement.data('color') + '-text';
		titleText = contentElement.data('title');
		subheaderText = contentElement.data('subheader');

		// Inject modal content
		if (topCallout && topCallout.length > 0) {
			modalTopCallout.text(topCallout);
			modalTopCalloutContainer.show();
		}
		modalPreheader.text(preheaderText);
		modalTitle.addClass(titleColorClass).text(titleText);
		modalSubheader.text(subheaderText);
		modalContent.html(content);

		// Close Button closes modal
		closeButton.on('click', function (e) {
			e.preventDefault();
			handleModalClose(selector);
		});

		// Overlay click closes modal
		modalOverlay.on('click', function (e) {
			e.preventDefault();
			handleModalClose(selector);
		});
	} else {
		// kysc-mirror-modal
		// Inject modal content
		$('#kysc-mirror-crop')
			.html('<i class="fa fa-crop"></i> Crop')
			.removeClass('cropping')
			.prop('disabled', false);
		modal.find('.modal-cancel').show();
		modalContent.html(content);
	}

	// Show modal
	modal.addClass('modal-open');
	modalOverlay.addClass('modal-open');
	body.addClass('modal-open');
	modal.focus();

	// If not kysc-mirror-modal
	if (selector != '#kysc-mirror-modal') {
		// Google Analytics Event Tracking -- Modal View
		trackEvent(
			'modal_view',
			'modal_view',
			modalPreheader.text().toLowerCase().replace(/ /g, '_'),
			modalTitle.text().toLowerCase().replace(/ /g, '_')
		);

		// Google Analytics Event Tracking -- Modal Buttons
		$.each(modal.find('.btn'), function () {
			$(this)
				.addClass('event-tracking-bound')
				.on('click', function () {
					trackEvent(
						'button_click',
						'button_click',
						modalPreheader.text().toLowerCase().replace(/ /g, '_'),
						modalTitle.text().toLowerCase().replace(/ /g, '_') +
							'_' +
							$(this).text().replace(/ /g, '_')
					);
				});
		});
	}
}

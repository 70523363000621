export function initHeader() {
	bindHeaderSearch();

	// Prevent .nav-category clicks from doing anything
	// These are menu items created through WordPress but do not need to link anywhere
	$('.nav-category > a').on('click', function (e) {
		e.preventDefault();
	});

	// open and close the primary side navigation menu:
	$('.js-open-nav').on('click', function (e) {
		e.preventDefault();
		e.stopPropagation();
		$('.mobile-header-nav-inner').scrollTop(0);
		$('.mobile-header-nav').addClass('is-open');
		$('html, body').addClass('no-scroll');
	});

	$('.js-close-nav').on('click', function (e) {
		e.preventDefault();
		$('.mobile-header-nav').removeClass('is-open');
		$('html, body').removeClass('no-scroll');
	});

	// Bind Mobile Nav on page load
	bindMobileHeaderNav();
}

function bindHeaderSearch() {
	$('#header-search-btn').on('click', function (e) {
		e.preventDefault();

		const searchButton = $('#header-search-btn');
		const searchBox = $('#header-search-box');
		const searchString = searchBox.val();
		const searchForm = $(this).closest('form');
		const searchBtnWidth = 40;
		const pagePadding = 40;
		const windowWidth = $(window).width();
		let inputWidth;

		// Unbind click to allow animation to complete
		searchButton.off('click');

		// Determine width of search input bar
		if (windowWidth < 544) {
			inputWidth = windowWidth - searchBtnWidth - pagePadding;
		} else if (windowWidth < 768) {
			inputWidth = windowWidth / 1.75 - searchBtnWidth - pagePadding;
		} else {
			inputWidth = 300;
		}

		// If searchString is empty, and isn't active, animate and show field
		if (searchString.length < 1 && !searchBox.hasClass('active')) {
			searchBox.focus();
			searchBox
				.addClass('active')
				.animate({ width: inputWidth }, '800', function () {
					// Rebind header search
					bindHeaderSearch();
				});
		}
		// If searchString is empty, and field is already active, animate and hide field
		else if (searchString.length < 1 && searchBox.hasClass('active')) {
			searchBox.animate({ width: 0 }, '800', function () {
				searchBox.removeClass('active');
				searchButton.blur();
				// Rebind header search
				bindHeaderSearch();
			});
		}
		// If searchString is not empty, submit search
		else {
			// Change icon to spinner
			searchButton
				.find('.fa')
				.removeClass('fa-search')
				.addClass('fa-spinner fa-spin')
				.css({ top: '30%', left: '25%' })
				.blur();
			// Track event in GA
			trackEvent(
				'search',
				'search',
				'search_query',
				searchString.toLowerCase().trim().replace(/ /g, '_')
			);
			// Submit form
			searchForm.submit();
		}
	});
}

// Expand / Collapse mobile header nav menu items
function bindMobileHeaderNav() {
	$('.mobile-nav').on(
		'click',
		'.mobile-header1-nav-menu > ul > li > a',
		function (e) {
			e.preventDefault();
			// Unbind click to allow animation to complete
			$('.mobile-nav').off('click');
			var menuItem = $(this);

			if (menuItem.hasClass('open')) {
				// Collapse menu item
				$(this)
					.siblings('.sub-menu')
					.slideUp('fast', function () {
						menuItem.removeClass('open');
						// Rebind menu clicks
						bindMobileHeaderNav();
					});
			} else {
				// Open menu item
				$(this)
					.siblings('.sub-menu')
					.slideDown('fast', function () {
						menuItem.addClass('open');
						// Rebind menu clicks
						bindMobileHeaderNav();
					});
			}
		}
	);
}

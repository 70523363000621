import '../scss/style.scss';

import Router from './utils/Router';
import common from './routes/common';
import home from './routes/home';
import singlePost from './routes/singlePost';
import pageTemplatePageCamps from './routes/pageTemplatePageCamps';
import pageTemplatePageMovies from './routes/pageTemplatePageMovies';
import sponsor from './routes/sponsor';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Specific pages/templates */
	home,
	singlePost,
	pageTemplatePageCamps,
	pageTemplatePageMovies,
	sponsor,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());

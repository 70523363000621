export default {
	init() {
		console.log(
			'camps page! (if you see this, delete it from pageTemplatePageCamps.js'
		);
		bindAgeGroupFilters();
	},
	finalize() {},
};

function bindAgeGroupFilters() {
	$('.age-group-filter').on('click', function (e) {
		e.preventDefault();

		// Unbind click until function completes
		$('.age-group-filter').off('click');

		const numGroups = $('.age-group-filter').length;
		let numChecked = $('.age-group-filter.active').length;
		const clickedFilter = $(this);
		const clickedGroup = $('#' + clickedFilter.data('group'));
		const filteredNotification = $('.dynamic-page-notification');

		// If none are active on first click, hide all except clicked group
		if (numChecked == 0) {
			$('.camp-age-group-sections')
				.not('#' + clickedFilter.data('group'))
				.fadeOut('fast');
			clickedFilter.addClass('active');
		} else {
			if (clickedFilter.hasClass('active')) {
				clickedGroup.fadeOut('fast');
				clickedFilter.removeClass('active');
			} else {
				clickedGroup.fadeIn('fast');
				clickedFilter.addClass('active');
			}

			// Reset numChecked after modifying active states
			numChecked = $('.age-group-filter.active').length;

			// if no filters are checked, show all groups
			if (numChecked == 0) {
				$('.camp-age-group-sections').fadeIn('fast');
			}
		}

		filteredNotification
			.html('<i class="fa fa-check-circle fa-fw"></i> Filter applied')
			.slideDown('fast')
			.promise()
			.then(function () {
				setTimeout(function () {
					filteredNotification.slideUp('fast').empty();
				}, 1500);
			});

		// Function finished, rebind clicks
		bindAgeGroupFilters();
	});
}

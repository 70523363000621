export function initEventTracking() {
	window.trackEvent = function (
		event_name,
		event_action,
		event_category,
		event_label,
		event_value
	) {
		if (typeof gtag !== 'undefined' && $.isFunction(gtag)) {
			event_name = event_name || null;
			event_category = event_category || null;
			event_action = event_action || null;
			event_label = event_label || null;
			event_value = event_value || null;

			if (event_name && event_action) {
				// Send GA Event
				gtag('event', event_name, {
					event_category: event_category,
					event_action: event_action,
					event_label: event_label,
					value: event_value,
				});
			} else {
				if (console) {
					if (!event_name) {
						console.log('GA Event Error: Missing event_name');
					} else if (!event_action) {
						console.log('GA Event Error: Missing event_action');
					}
				} // end if console
			} // end if
		} // end if gtag is defined
	};
}

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

export default {
	init() {
		initMirror();

		// Resize mirror container for header and notification
		resizeMirrorContainer();

		// Scroll Latest News section into view
		$('#latest-news-tab').on('click', function (e) {
			e.preventDefault();

			// Track click
			if (typeof trackEvent === 'function') {
				trackEvent(
					'latest_news_click',
					'click',
					'homepage_latest_news',
					'homepage_click_to_view_latest_news'
				);
			}

			// Scroll into view
			$('html, body').animate(
				{ scrollTop: $('#latest-news-tab').offset().top - 100 },
				'800'
			);
		});

		// Initialize Slick slider
		$('.latest-news-slider').slick({
			arrows: true,
			easing: 'swing',
			autoplay: true,
			slidesToScroll: 1,
			slidesToShow: 1,
			initialSlide: 1,
			infinite: true,
			mobileFirst: true,
			draggable: false,
			swipe: true,
			adaptiveHeight: true,
			prevArrow:
				'<button class="slick-prev"><i class="fa fa-chevron-circle-left"></i> </button>',
			nextArrow:
				'<button class="slick-next"><i class="fa fa-chevron-circle-right"></i> </button>',
			responsive: [
				{
					breakpoint: 544,
					settings: {
						slidesToShow: 2,
						dots: false,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						dots: false,
					},
				},
			],
		});

		$(window).on('resize orientationchange', function () {
			resizeMirrorContainer();
			$('.latest-news-slider').slick('resize');
		});
	},
	finalize() {},
};

function resizeMirrorContainer() {
	const mirrorContainer = $('#kysc-mirror-container');
	const notificationHeight = $('.site-wide-notification').height();
	const headerHeight = $('header').height();

	mirrorContainer.css(
		'height',
		'calc(100vh - ' + notificationHeight + 'px - 2rem - ' + headerHeight + 'px)'
	);
}

function initMirror() {
	if ($('#kysc-mirror-container').length) {
		// Set mirror fallback image
		$(window).bind('load', function () {
			if (KYSC_MIRROR_FALLBACK_IMAGE) {
				$('#kysc-mirror-container').css(
					'background-image',
					'url(' + KYSC_MIRROR_FALLBACK_IMAGE + ')'
				);
			}
		});

		// If mirror not blocked (not IE 11) Show 'Try Your Own Image' button
		if (!$('body').hasClass('kysc-mirror-blocked')) {
			window.startMirrorCrop = function () {
				$('#kysc-mirror-crop')
					.html('<i class="fa fa-spinner fa-spin"></i> Cropping...')
					.addClass('cropping')
					.prop('disabled', true);
				$('#kysc-mirror-modal').find('.modal-cancel').hide();
				$('#kysc-mirror-modal').find('.modal-content').html('');
			}; // end startMirrorCrop
		}

		// If mirror not blocked after delay Show 'Try Your Own Image' button
		setTimeout(function () {
			if (
				!$('body').hasClass('kysc-mirror-blocked') &&
				$('#kysc-mirror-container > canvas').length
			) {
				$('.kysc-mirror-toolbar').fadeIn('fast', function () {
					$('.mirror-upload-btn')
						.addClass('event-tracking-bound')
						.on('click', function () {
							trackEvent(
								'button_click',
								'button_click',
								'homepage_mirror',
								$(this)
									.find('label')
									.text()
									.toLowerCase()
									.trim()
									.replace(/ /g, '_')
							);
						});
				});
			} // end if mirror not blocked
		}, 500);
	} // end if mirror is present
}

export function initFooter() {
	// Bind Full Site Map on page load
	bindFooterFullSiteMap();

	// Back to top button
	$('.back-to-top-link').on('click', function (e) {
		e.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, '800', function () {
			$('body').trigger('click');
		});
	});

	// Fade in/out .back-to-top
	$(window).scroll(function () {
		if ($(this).scrollTop() > 300) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
}

// Expand / Collapse Full Site Map
function bindFooterFullSiteMap() {
	$('.footer-expand-link').on('click', function (e) {
		e.preventDefault();
		// Unbind click to allow animation to complete
		$(this).off('click');
		const expandLink = $(this);
		const linkText = expandLink.find('span');
		const sitemap = $('.footer-full-site-map');

		if (expandLink.hasClass('open')) {
			// Collapse menu item
			sitemap.slideUp('fast', function () {
				expandLink.removeClass('open');
				$(this).removeClass('open');
				// Change link verb
				linkText.text('Open');
				// Rebind menu clicks
				bindFooterFullSiteMap();
			});
		} else {
			// Open menu item
			sitemap.slideDown('fast', function () {
				expandLink.addClass('open');
				$(this).addClass('open');

				// Change link verb
				linkText.text('Close');

				// Rebind menu clicks
				bindFooterFullSiteMap();

				// Track event
				if (typeof trackEvent === 'function') {
					trackEvent(
						'footer_sitemap',
						'footer_click',
						'footer',
						'footer_sitemap_click_to_view'
					);
				}
			});
		}
	});
}

export default {
	init() {
		console.log('single post! if you see this delete it from singlePost.js');

		const kyscPage = $('.kysc-page');
		const windowWidth = $(window).width();
		const hasFeaturedImage =
			kyscPage.find('.blog-single-post').hasClass('has-featured-image') ||
			false;
		const hasSidebar =
			kyscPage.find('.kysc-page-content').hasClass('has-sidebar') || false;
		const featuredImageHeight =
			hasFeaturedImage == true ? $('.kysc-featured-image').height() : 0;
		const sidebarHeight =
			hasSidebar == true ? $('.blog-single-post-sidebar').height() : 0;
		const adjustment = windowWidth < 992 ? 300 : 150;

		// Adjust .kysc-page container min-height to account for featured-image(?) and/or sidebar(?)
		if (windowWidth > 767) {
			kyscPage.css(
				'min-height',
				parseInt(featuredImageHeight) +
					parseInt(sidebarHeight) +
					adjustment +
					'px'
			);
		}
	},
	finalize() {},
};
